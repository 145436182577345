import { PLUGIN_OPERATIONS, DIRECT_PLUGIN } from '@openedx/frontend-plugin-framework';
import { DatadogLoggingService } from '@edx/frontend-logging';
import { HelpChatbot } from '@edx/frontend-plugin-authn';

const config = {
  OPTIMIZELY_FULL_STACK_SDK_KEY: "B1ha73pEGEG2rbcVZSRGj",
  loggingService: DatadogLoggingService,
  pluginSlots: {
    main_app_slot: {
      plugins: [
        {
          op: PLUGIN_OPERATIONS.Insert,
          widget: {
            id: 'xpert_chatbot_plugin',
            type: DIRECT_PLUGIN,
            priority: 60,
            RenderWidget: HelpChatbot,
          },
        },
      ],
    }
  },
};

export default config;
